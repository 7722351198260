<template>
    <div>
        <custom-button :class="additionalButtonClasses" @click.native="displayModal = true" :colourType="buttonType">
            {{title ? title : action.title}}
        </custom-button>
        <portal to="destination">
            <large-model v-show="displayModal" :submitting="submittingModal" :title="action.title"
                        :confirmationString='`Are you sure you want to \"${action.title}\"`'
                        @removeFromDisplay="displayModal = false" @perform="perform()">

                <div class="grid grid-cols-2 gap-2">
                    <div class="grid grid-cols-2 auto-rows-min">
                        <collapsable-header class="col-span-2" :collapsable="false" title="Contract Information">
                        </collapsable-header>
                        <template v-for="field in action.fields.filter(field => field.type !== 'hidden' && (field.name === 'name' || field.name === 'prefix' || field.name === 'address' || field.name === 'user_id' || field.name === 'sage_reference') )">
                            <div class="whitespace-normal py-2 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left w-full text-v3-gray-900 text-opacity-40 dark:text-white dark:text-opacity-40">
                                {{ field[1].title }}
                                <span class="text-v3-gray-900 text-opacity-20 dark:text-white dark:text-opacity-20" v-if="isActionRequired('name')">*</span>
                            </div>
                            <div class="whitespace-normal px-3 py-2 text-sm text-left w-full">
                                <property-or-field
                                    :action="action"
                                    :fieldId="field[0]"
                                    :show-label="false"
                                    v-model="formData[field[0]]">
                                </property-or-field>
                                <div v-show="errors.has(field[0])" class="text-v3-red text-sm">{{ errors.first(field[0]) }}</div>
                            </div>
                        </template>
                    </div>
                    <div class="grid grid-cols-2 auto-rows-min">
                        <collapsable-header class="col-span-2" :collapsable="false" title="Approval & Financial Details">
                        </collapsable-header>
                        <template v-for="field in action.fields.filter(field => field.type !== 'hidden' && (field.name === 'is_approval_required' || field.name === 'approval_users' || field.name === 'delivery_contact_id' || field.name === 'budget') )">
                            <div class="whitespace-normal py-2 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left w-full text-v3-gray-900 text-opacity-40 dark:text-white dark:text-opacity-40">
                                {{ field[1].title }}
                                <span class="text-v3-gray-900 text-opacity-20 dark:text-white dark:text-opacity-20" v-if="isActionRequired('name')">*</span>
                            </div>
                            <div class="whitespace-normal px-3 py-2 text-sm text-left w-full">
                                <property-or-field
                                    :action="action"
                                    :fieldId="field[0]"
                                    :show-label="false"
                                    v-model="formData[field[0]]">
                                </property-or-field>
                                <div v-show="errors.has(field[0])" class="text-v3-red text-sm">{{ errors.first(field[0]) }}</div>
                            </div>
                        </template>
                    </div>
                </div>
            </large-model>
        </portal>
    </div>
</template>

<script>
import fileDownload from "js-file-download";
import LargeModel from "@/v3/models/LargeModel.vue";
import CustomButton from "@/v3/Buttons/CustomButton.vue";
import PropertyOrField from "@/components/PropertyOrField.vue";
import CollapsableHeader from "@/v3/components/headers/CollapsableHeader.vue";

export default {
    name: "AddContractForm",
    data() {
        return {
            submittingModal: false
        }
    },
    components: {
        CollapsableHeader, PropertyOrField,
        CustomButton,
        LargeModel,
    },
    props: {
        action: {
            type: Object,
        },
        actionKey: {
            type: String
        },
        onSuccessPath: {
            type: String
        },
        redirectOnSuccess: {
            type: Boolean,
            default: true
        },
        title: {
            type: String,
            default: null
        },
        additionalButtonClasses: {
            type : String,
            default: null
        },
        displayModal:{
            type: Boolean,
            default: false
        },
        buttonType:{
            type: String,
            default: 'primary'
        },
    },
    computed: {
        formData() {
            if (!this.action.fields) {
                return {};
            }
            var payload = {};
            this.action.fields.forEach(function (field) {
                if (field.value !== undefined) payload[field.name] = field.value;
            });
            return payload;
        },
    },
    methods: {
        getFieldComponent(field) {
            if(field.title.includes("(GBP)")){
                return 'gbp-input';
            }

            return field.type + '-input';
        },
        perform() {
            this.submittingModal = true;
            this.action.perform(this.formData).then(res => {
                if (res.type === 'text/csv') {
                    fileDownload(res.text, 'export.csv');
                    this.submittingModal = false;
                    return;
                }
                this.displayModal = false;
                this.submittingModal = false;
                this.$store.dispatch(
                    "setMessage",
                    this.messages(res).entity.properties.get("success")
                );
                if (this.redirectOnSuccess) {
                    let newPath = this.onSuccessPath;
                    let fullPath = this.$router.currentRoute.fullPath;

                    if(newPath  !== fullPath) {
                        this.$router.push(this.onSuccessPath ? this.onSuccessPath : '/orders');
                    }else{
                        this.$router.go(0);
                    }
                }
                this.$emit('success', res);
            }).catch(error => {
                this.submittingModal = false;
                if(error.status === 422) {
                    this.$setLaravelValidationErrorsFromResponse(
                        JSON.parse(error.response.text)
                    );
                }else{
                    alert('There was an error processing your request.' + error);
                }
                this.$emit('failure');
            });
        },
        messages(response) {
            return response.body.entities
                .filter(function (entity) {
                    return entity.rels.contains("status-messages");
                })
                .first();
        },
        getField(fieldName){
            return this.action.fields.filter(field => field.name === fieldName).first();
        },
        isActionRequired(fieldId){
            if (!this.action) {
                return false;
            }
            let field = this.action.fields.filter(field => field.name === fieldId).first();
            if(field){
                if(field.validation != null) {
                    return field.validation.includes('required')
                }
            }
            return false;
        },
    },
}
</script>

