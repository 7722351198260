<template>
    <div class="inline-flex"
        @keydown.esc="toggleLocation">
        <template v-if="showLocation">
            <portal v-if="usePortal" to="destination">
                <location-picker :x="x" :y="y" @removeFromDisplay="showLocation = false" @sendLocation="sendLocation"></location-picker>
            </portal>
            <location-picker :x="x" :y="y" @removeFromDisplay="showLocation = false" @sendLocation="sendLocation"></location-picker>
        </template>
        <a href="#" @click.prevent="toggleLocation" target="_blank">
            <slot name="icon">
                <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                </svg>
            </slot>
        </a>
        <slot></slot>
    </div>
</template>

<script>
    import LocationPicker from "@/pages/LocationPicker"

    export default {
        components: {
            LocationPicker,
        },
        props: {
            x: {
                type: String
            },
            y: {
                type: String
            },
            usePortal:{
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                showLocation: false
            }
        },
        methods: {
            toggleLocation() {
                this.showLocation = !this.showLocation
            },
            sendLocation(location){
                this.$emit('sendLocation', location)
            }

        },
    }
</script>

<style scoped>

</style>
