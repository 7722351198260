<template>
  <div class="bg-v3-gray-50 bg-opacity-10 rounded-xl h-full p-2 col-span-2 overflow-hidden"
       v-if="details || loadingDetails">
    <div v-if="details" class="text-center sticky top-0 h-full grid grid-cols-3 w-ful gap-2 overflow-hidden">
      <div class="w-full rounded-xl bg-v3-white-100 dark:bg-v3-gray-900 h-full overflow-y-auto">
        <div class="flex flex-col h-full">
          <div class="m-2">
            <div class="text-center">
              <div class="flex justify-between" v-if="hasTable">
                <a @click="$emit('hideTable')">
                  <svg v-if="!hideTable" class="text-v3-gray-50 opacity-80 dark:text-v3-gray-400 w-6 h-6 cursor-pointer"
                       xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
                    <path fill="currentColor" d="M15.41 16.59L10.83 12l4.58-4.59L14 6l-6 6l6 6z"/>
                  </svg>
                  <svg v-else class="text-v3-gray-50 opacity-80 dark:text-v3-gray-400 w-6 h-6 cursor-pointer"
                       xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
                    <path fill="currentColor" d="M8.59 16.59L13.17 12L8.59 7.41L10 6l6 6l-6 6z"/>
                  </svg>

                </a>
                <a @click="openItemInNewTab">
                  <svg class="text-v3-gray-50 opacity-80 dark:text-v3-gray-400 w-6 h-6 cursor-pointer"
                       xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
                    <path fill="currentColor"
                          d="M19 19H5V5h7V3H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14c1.1 0 2-.9 2-2v-7h-2zM14 3v2h3.59l-9.83 9.83l1.41 1.41L19 6.41V10h2V3z"/>
                  </svg>
                </a>
              </div>

                <p class="text-xl font-bold mt-6">{{details.properties.get('name')}}</p>
                <p class="text-sm font-bold" v-html="details.properties.get('address')">{{details.properties.get('address')}}</p>

                <header-link class="my-4" @click="openTab('basics')" :isActive="tabOpen === 'basics'" :activeClass="activeClass">
                    <template v-slot:title>
                        <div Class="text-left">
                            Details
                        </div>
                    </template>
                </header-link>
                <header-link class="my-4" @click="openTab('contracts')" :isActive="tabOpen === 'contracts'" :activeClass="activeClass">
                    <template v-slot:title>
                        <div Class="text-left">
                            Contracts
                        </div>
                    </template>
                </header-link>
            </div>

          </div>

        </div>
      </div>
      <div class="w-full rounded-xl bg-v3-white-100 dark:bg-v3-gray-900 h-full col-span-2 overflow-y-auto text-left">
        <div class="p-2 h-full w-full">
            <div v-if="tabOpen === 'basics'">
                <project-details-pagination ref="projectEditDetails" :entity="details" :editing="editing" :updateActionData="updateActionData" :updateAction="updateAction" @editClicked="editClicked" @saveClicked="saveClicked"></project-details-pagination>
            </div>
            <div v-if="tabOpen === 'contracts'">
                <project-contracts-pagination :entities="contracts" @successForm="$emit('successForm', details)">
                    <template v-slot:additional-buttons>
                        <template v-for="(action, index) in details.actions.filter(action => action.name === 'add-new-site')">
                            <add-contract-form title="Create" :response="details" additionalButtonClasses="my-2 w-full" :action="action[1]" :actionKey="action[0]" :redirectOnSuccess="false" :onSuccessPath="$router.currentRoute.fullPath" @success="$emit('successForm', details)"></add-contract-form>

                        </template>
                    </template>
                </project-contracts-pagination>
            </div>
        </div>
      </div>
    </div>
    <loading v-else class="sticky top-0"></loading>

      <modal v-if="showModal" @removeFromDisplay="showModal = false" @perform="showModal = false" title="Unsaved Changes" confirmationString="" :has-confirmation-action="false">
            Please save changes before changing page.
      </modal>
  </div>
</template>
<script>
import HeaderLink from "@/v3/components/headers/HeaderLink.vue"
import Loading from '@/components/Loading'
import {mapGetters} from "vuex";
import ConfirmationAction from "@/v3/components/forms/ConfirmationAction.vue";
import FormAction from "@/v3/components/forms/FormAction.vue";
import DownloadPdf from "@/pages/DownloadPdf.vue";
import CollapsableHeader from "@/v3/components/headers/CollapsableHeader.vue";
import Modal from "@/v3/components/modals/Modal.vue";
import ProjectDetailsPagination from "@/v3/components/pagination/ProjectDetailsPagination.vue";
import ProjectContractsPagination from "@/v3/components/pagination/ProjectContractsPagination.vue";
import AddContractForm from "@/v3/components/forms/AddContractForm.vue";

export default {
  name: 'SingleProjectDetails',
  components: {
      AddContractForm,
      ProjectContractsPagination,
      ProjectDetailsPagination,
      Modal,
      CollapsableHeader,
      DownloadPdf,
    FormAction,
    ConfirmationAction,
    HeaderLink,
    Loading
  },
    data(){
      return {
          editing: false,
          tabOpen: 'basics',
          pdfLoading: false,
          savingItems: false,
          showPDF: false,
          invoices:null,
          reoccurringInvoiceItems: null,
          departments: null,
          projects: null,
          contracts: null,
          subscriptions: null,
          permissions: null,
          showModal: false
      }
    },
    props:{
      details: {
          type: Object,
          default: null
      },
        hideTable: {
          type: Boolean,
            default: false
        },
        loadingDetails:{
          type: Boolean,
            default: false
        },
        hasTable:{
          type: Boolean,
            default: true
        },
        app:{
            type: String,
            default: 'base'
        },
        activeClass:{
          type: String,
            default: 'bg-v3-purple bg-opacity-20 dark:bg-v3-purple dark:bg-opacity-20'
        }
    },
    mounted() {
      if(this.details) {
          this.getAllDetails();
      }
    },
    computed: {
        ...mapGetters({
            accessToken: "getAccessToken",
            user: "getUser"
        }),
        initials() {
            if (this.orderUser.entity && this.orderUser.entity.properties.get('name')) {
                return this.orderUser.entity.properties.get('name')
                    .replace(/[^a-zA-Z- ]/g, "")
                    .match(/\b\w/g)
                    .join("");
            }
        },
        orderUser(orderEntity) {
            let orderUser = this.details.entities
                .filter(function (entity) {
                    return entity.rels.contains("user");
                })
                .first();

            if (!orderUser) {
                return null;
            }

            return orderUser;
        },
        updateAction() {
            if (!this.details) {
                return null;
            }
            return this.details.actions.filter(action => action.name === 'update-project').first()
        },
        updateActionData() {
            if (!this.updateAction || !this.updateAction.fields) {
                return {};
            }
            var payload = {};
            this.updateAction.fields.forEach(function (field) {
                if (field.value !== undefined) payload[field.name] = field.value;
            });
            return payload;
        },
        remainingActions() {
            return this.details.actions
                .filter(action =>
                    action.name !== 'add-new-item' &&
                    action.name !== 'update-client'
                );
        },
    },
    methods:{
        openItemInNewTab(){
            let selfLink = this.details.links.filter(link => link.rels.contains('self')).first().href;
            let parts = selfLink.split('/api');
            window.open('/' + this.app  + parts[1], '_blank');
        },
        editDetails(){
            this.editing = true;
        },
        messages(response) {
            return response.body.entities
                .filter(function (entity) {
                    return entity.rels.contains("status-messages");
                })
                .first();
        },
        async saveItems(){
            if (!this.savingItems) {
                this.savingItems = true;
                this.$refs.items.saveItems().then(res => {
                    this.editing = false;
                    this.savingItems = false;
                    let entity = {'entity': this.details};
                    this.$emit('setLoading');
                    this.$emit('getDetails', entity);

                    this.$store.dispatch(
                        "setMessage",
                        'The qas were saved successfully'
                    );
                }).catch(error => {
                    if(error.status !== 422) {
                        alert('There was an error processing your request.' + error);
                    }
                    this.$setLaravelValidationErrorsFromResponse(JSON.parse(error.error.response.text), error.scope);
                    this.savingItems = false;
                })
            }
        },
        removeItem(){
            let entity = {'entity': this.details};
            this.$emit('setLoading');
            this.$emit('getDetails', entity);
        },
        getTitle(name, title){
            if(name === 'delete-invoice'){
                return 'Delete';
            }
            if(name === 'record-partial-payment'){
                return 'Partial Payment';
            }if(name === 'record-full-payment'){
                return 'Full Payment';
            }

            return title;
        },
        getColourType(action, index){
            if(action === 'delete-order' || action.includes('reject') || action.includes('delete')){
                return 'negative';
            }
            if(action.includes('approve') || action.includes('accept')) {
                return 'positive';
            }
            return  index === 0 ? 'primary': 'secondary';
        },
        formSuccess(res){
            if(res && res.body) {
                let details = res.body;
                let entity = {'entity': details};
                this.$emit('setLoading');
                this.$emit('getDetails', entity);
            }else{
                let entity = {'entity': this.details};
                this.$emit('setLoading');
                this.$emit('getDetails', entity);
            }
        },
        downloadPdf(){
            this.pdfLoading = true;
            this.details.actions.filter(action => action.fields.isEmpty() && action.name === 'download-pdf').first().perform().responseType('blob').then(res => {
                var fileURL = window.URL.createObjectURL(res.xhr.response);
                var fileLink = document.createElement('a');
                fileLink.href = fileURL;
                let pdfName = this.details.properties.get('pdf_name');
                fileLink.setAttribute('download', pdfName + '.pdf');
                document.body.appendChild(fileLink);
                fileLink.click();
                this.pdfLoading = false;
            }).catch(error => {
                this.pdfLoading = false;
                alert('There was an error processing your request.' + error);
                this.$emit('failure');
            });
        },
        getLogo(logo){
            let clientLogo = logo;
            if (logo && logo.startsWith('public/')) {
                clientLogo = logo.replace('public/', '');
            }
            clientLogo = process.env.VUE_APP_API_URL + '/storage/logo/' + clientLogo
            return clientLogo;
        },
        getContracts(){
            this.contracts = this.details.entities.filter(entity => entity.rels.contains('site'));
        },
        getAllDetails(){
            this.getContracts();
        },
        editClicked(){
            this.editing=true;
        },
        openTab(tabName){
            if(!this.editing){
                this.tabOpen = tabName;
            }else{
                this.showModal = true;
            }
        },
        saveClicked(){
            this.updateActionData['latitude'] = this.$refs.projectEditDetails.latitude;
            this.updateActionData['longitude'] = this.$refs.projectEditDetails.longitude;
            this.$emit('saveClicked');
        }
    },
    watch: {
        details: function (val) {
            if(val !== null) {
                this.getAllDetails();
            }
        }
    }
}
</script>
